import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

const isBrowser = () => typeof window !== 'undefined'

const SuccessPage = () => {
  isBrowser() && window.localStorage.setItem('cartItems', '[]')
  return (
    <Layout>
      <div>
        <section className="section section--gradient">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="content">
                    <div className="tile">
                      <p className="title">
                        Your order has been successfully paid for more info
                        check your email.
                      </p>
                    </div>
                    <div className="columns">
                      <div className="column is-12 has-text-centered">
                        <Link className="btn" to="/store">
                          Shop again
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default SuccessPage
